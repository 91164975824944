.nav-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.2rem 0.4rem;
  margin: 0.75rem 0;
  transition: padding 1s;

  &.disabled-item {
    opacity: 0.9;
    &:hover {
      color: inherit;
      cursor: default;
    }

  }
  &:hover {
    color: inherit;
    cursor: pointer;
  }
  .icon{
    width: 1.2rem;
    height: 100%;

  }
  .fade-in-text {
    font-family: "Poppins";
    animation: fadeIn 1s;
    padding-top: 2px;
    color: inherit;
    font-size: 0.9rem;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
}