.entity-select-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(119deg, #8D2A80 0%, #252546 100%);
  .loot8-logo-image{
    height: clamp(3rem, 4vw, 5rem);
    width: 100%;
    object-fit: contain;
  }

}