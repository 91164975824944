.root-profile{
    padding:20px;
    height: 90vh;    
    .sub-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 100vh;
        max-width: 50vw;
    }
    
    .profile-image {
        width: 200px;
        height: 200px;
    }
    
    .user-name {
        font-family: 'Poppins-SemiBold';
        font-size: 1.8rem;
        text-transform: uppercase;
        text-align: center;
        color: #252546;
    }
    
    .verified-badge {
        // position: absolute;
        top: 10px;
        right: 10px;
    }
    
    .connected-account-text {
        font-family: 'Poppins-Medium';
        font-size: 1rem;
        margin: 0px !important;
        text-transform: capitalize;
    }
    
    .verified-icon {
        width: 10px;
        height: 10px;
        margin-right: 2px;
    }
    
    .unlink-desc {
        font-family: 'Poppins-Medium';
        margin-left: 2px;
        font-size: 0.85rem;
        margin-top: 0.4rem;
        color: #252546;
    }
    
    .user-unverified-text {
        color: #2C96FF !important;
    }
    
    .selected-account-icon {
        width: 20px;
        margin-right: 3px;
        height: 20px;
    }
    
    .user-verified-text {
        color: #FF0000;
        margin-left: 20px;
        font-family: "Poppins-Medium";
        font-size: 0.7rem;
    }
    
    .MuiOutlinedInput-root{
        padding: 0px !important; 
    }
    
    .edit-container {
        position: absolute;
        background-color: #F4B540;
        bottom: 0px;
        right:6rem;
        z-index: 999;
        border-radius: 20px;
        padding: 10px;
    }
    

@media screen and (max-width:640px) {
    .edit-container {
        right:-3rem ;
    }
}

    .edit-icon-input{
        background-color: #F4B540;
        border-radius: 20px;
        padding: 5px;
        width: 27px;
        height: 27px;
    }
    
    
    .close-icon-input{
        border-radius: 20px;
        padding: 5px;
        width: 27px;
        height: 27px;
    }

    .unlink-text {
        font-family: 'Poppins-Medium';
        color: #252546;
        text-transform: capitalize;
        font-style: italic;
        text-align: left;
        font-size: 1rem;
        text-decoration: underline !important;
    }
    
    .social-link {
        font-family: "Poppins-Medium";
        word-wrap: break-word;
        word-break: break-all;
        max-width: 40%;
        font-size: 0.8rem;
        color: #3A559F;
    }
    
    .social-text {
        font-family: "Poppins-Medium";
        padding-left: 0px;
        margin-top: 1rem;
        font-size: 1.1rem;
        color: #252546;
    }
    
    .warning-icon {
        margin-top: 10px;
        width: 10px;
        height: 10px;
    }
    
    .social-links-container {
        flex-direction: row;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .social-icons {
        width: 42px;
        height: 42px;
    }
    
    .social-btn {
        min-width: 6rem;
        flex-direction: column;
        display: flex;
    }
    
    .social-icon-text {
        text-transform: uppercase;
        margin-top: 0.5rem;
        font-size: 0.8rem;
        font-family: 'Poppins-Medium';
    }
    
    .unlink-container {
        width: 20rem;
        right: -11.5rem
    }
    
    .MuiButton-root{
        padding: 0px !important;
        margin: 0px !important;
        border: 0px;
        background: none;
        outline: none;
    }
    
    }