.main-content-profile-creation{
    
    max-height: 75%;
    overflow-y: scroll;
    margin-bottom: 5rem;

    ::-webkit-scrollbar {
        display: none;
    }


.heading-text-loot8{
    color: #252546;
    font-family:'Poppins-Medium';
    font-size: 1.2rem;
}

.profile-sub-container{
    justify-content: center;
    display: flex;
}

}


.main-content-profile-creation::-webkit-scrollbar {
    width: 0; 
    height:0
  }