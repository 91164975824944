
.thirdparty-collection-container {

  max-width: calc(100vw - 5rem);
  overflow: auto;
  height: 100%;
  padding: 1rem;
  background-image: url("../../assets/images/background/whiteTexture-R.png");
  background-size: 545px auto;
  background-position: top right;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-color: white;


  .thirdparty-collection-container-title {
    font-family: "Poppins-SemiBold";
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #252546;

  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar track */
    height: 6px; /* Adjust the height of the scrollbar track */
    background-color: transparent; /* Adjust the background color of the scrollbar track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4B4B79; /* Adjust the color of the scrollbar thumb */
    border-radius: 6px; /* Adjust the border-radius of the scrollbar thumb */
  }

  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #464690; /* Adjust the color of the scrollbar thumb on hover */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #4B4B79; /* Adjust the color of the scrollbar thumb when active/pressed */
  }
  .border{
    background: #efefef !important;
    
  }
}

@media (max-width: 1200px) {
  .thirdparty-collection-container {

    max-width: calc(100vw - 5rem);
  
  }
}