.modal-container {
  font-family: "Poppins-Medium";

  .modalBody {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .input-select-chain-value {
    width: 100%;
    background-color: #FFFFFF;
    letter-spacing: 0px;
    text-align: left;
    font: normal normal normal 1rem 'Poppins';
    border-radius: 5px;
  }
  .header-container {
      text-align: center;
      color: #FFFFFF;
      font-family: 'Poppins';
      padding: 10px;
      background-color:#C63FB4 ;
      width: 100%;
  }
  .preview {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
  }
  .preview-front, .preview-back {

      background-color: #FFFFFF;
      border: 0.5px solid;
      border-color: #0000004A;
      margin: 10px;
      padding: 5px;
      position: relative;
      display: flex;
      flex-direction: column;
  }
  .preview-back {
      padding-right: 0px;
      padding-left: 0px;
  }
  .cardTitle {
      font-weight: 500;
      width: 100%;
      color: #000000;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
  }
  .section {
      width: 100%;
      height: 100%;
      // margin-top: 7px;
      display: flex;
      align-items: center;
      flex-direction: column;
      // padding: 10px;
      // border: 5px solid;
  }
  .qr-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60%;
      align-items: center;
  }
  .cardImage {
      object-fit: contain;
      height: 100%;
      width: 100%;
      align-self: center;
      display: flex;
      justify-content: center;
  }
  .qrImage {
      object-fit: contain;
      align-self: center;
      display: flex;
      justify-content: center;
  }
  .tokenId-section {
      font-size: 15px;
      font-weight: 600;
      align-self: center;
      width: 100%;
      color: #FCFCFC;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .logoSection {
      width: 100%;
      display: flex;
      bottom: 10px;
      position: absolute;
      justify-content: center;
      align-items: center;
  }
  .logoImage {

      height: 25px;
      // width: 100px;
      object-fit: contain;
  }
  .TcflogoImage {

      height: 40px;
      // width: 100px;
      object-fit: contain;
  }
  .configuration {
      display: flex;
      flex-direction: column;
  }
  .countSelector {
      display: flex;
      flex-direction: row;
      margin: 10px
  }
  .input-label {
      color: #000000;
      font-size: 14px;
      font-family: 'Poppins'
  }
  .color-picker {
      margin-left: 5px;
      margin-right: 5px;
  }
  .backgroundColorSection {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .submit-section {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 60%;
  }
  .count-section {
      display: flex;
      margin-top: 15px;
      flex-direction: row;
  }
  .input-val {
      border: 2px solid;
      border-radius: 3px;
      border-color: #424242;
  }
  .input-label {
      width: 100% !important;
  }
}