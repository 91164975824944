.background-container-blue {
    width: 100%;
    min-height: 100vh; 
    background-image: url("../../../assets/images/background/white-background-new.svg");
    background-size:  cover; 
    background-repeat: no-repeat; 
    box-sizing: border-box;
    background-color: white;
    border-image-width: 150px;
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    padding-top: 2rem;
    position: relative;
    
  .loot8-logo-image{
    height: clamp(5rem, 5vw, 5rem);
    width: clamp(7rem, 8vw, 9rem);
    object-fit: contain;
  }
  .welcome-text {

    font-family: 'Poppins-SemiBold';
  font-size: 50px; /* Size from the image */
  color: #FFFFFF; /* White color for the text */
  text-shadow: 
    -1px -1px 0 #000, 
     1px -1px 0 #000,
    -1px 1px 0 #000, 
     1px 1px 0 #000; /* Black text shadow to create the outline effect */
  }
  .establishment-portal-text {
    font-family: "Poppins-SemiBold";
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    opacity: 0.9;
    color: #252546;
  }
  .short-description-text{
    font-family: "Poppins-medium";
    font-size: clamp(0.9rem, 1.8vw, 0.5rem);
    opacity: 0.9;
    color: #252546;
    margin-top: 1rem;
  }
  .sub-heading{
    font-family: "Poppins-SemiBold";
    color: #252546;
    // text-decoration: underline;
    font-size: clamp(0.5rem, 2vw, 1.2rem);
  }
}
@media screen and (max-width:961px) {
  .background-container-blue {
    display: none;
  }
}