.sidebar-container {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: rgba(37, 37, 70, 0.70);
  background-image: url("../../assets/images/background/blueTexture.png");
    background-size: cover; 
    background-repeat: no-repeat; 
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 3px );
  -webkit-backdrop-filter: blur( 3px );
  overflow-y: auto;
  width: 15rem;
  transition: all 1s;

  .sidebar {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 15px;

    .top-bar {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-auto-flow: column;
      justify-content: center;
    }

    .menu-icon {
      display: flex;
      align-self: flex-end;
      margin: 0.1rem;
      // background-color: #3F3F6A;
      border-radius: 4px;
      margin-bottom: 0.25em;
    }
    .logo-image {
      display: flex;
      width: 50%;
      align-items: center;
      margin-top: 1rem;
      // align-self: center;
      transition: all ease-in-out 0.5s;
    }
    .routes {
      color: #FCFCFC;
      .nav-link {
        text-decoration: none !important;
        border-radius: 6px;
        &:hover {
          color: #F4B540 !important;
          .icon {
            filter: invert(23%) sepia(36%) saturate(2201%) hue-rotate(325deg) brightness(103%) contrast(94%);
          }
        }
      }
      .nav-link-active {
        color: #252546 !important;
        background: #F4B540;
        .icon {
          filter: invert(9%) sepia(25%) saturate(1795%) hue-rotate(204deg) brightness(24%) contrast(93%);
        }
        &:hover {
          color: #252546 !important;
          .icon {
            filter: invert(9%) sepia(25%) saturate(1795%) hue-rotate(204deg) brightness(24%) contrast(93%);
          }
         }
      }
      .invite-code-container {
        width: 85%;
        padding-bottom: 1rem;
        background: #404079 0% 0% no-repeat padding-box;
        margin: auto;
        margin-top: 3rem;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1.5px solid #ffffff;
        animation: fadeIn 1s;

        
        @keyframes fadeIn {
          0% { opacity: 0; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
        .lock-icon {
          object-fit: contain;
          width: 65%;
          margin: auto;
          margin-top: -2rem;
        }
        .subtext {
          font-family: "Poppins";
          font-size: 0.75rem;
        }
      }
    }
  }
  .logout {
    display: flex;
    font-family: "Poppins-Medium";
    color: #F4B540;
    font-weight: 600;
    align-items: center;
    padding: 0 15px 15px 15px;
  }
  
}

.side-bar-main-component {
  .__floater__open {
    backdrop-filter: blur(6px) !important;
  }
  }

#walkthrough-container{
  border: 1px solid #F4B540 !important;
  border-radius: 12px !important;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

#walkthrough-main-content{
  color: #fff;
  word-wrap: break-word;
  font-family: 'Poppins-Medium';
  max-width: 28rem;
}

