.passport-card {
  .passport-image {
    object-fit: contain;
    background-color: black;
    object-position: center;
    // width: 100%;
    // height: 100%;
    // max-height: 12rem;
    // min-height: 10rem;
    width: 20rem;
    height: 12rem;
  }
  .passport-image.selected {
    border: 3px dashed #F4B540;
  }

  @media screen and (max-width:500px) {
    .passport-image {
    width: 18rem;
    }
  }
}