.dashboard-container {
  overflow: auto;
  height: 100%;
  padding: 1rem;
  background-image: url("../../assets/images/background/whiteTexture-R.png");
  background-size: 545px auto;
  background-position: top right;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-color: white;

  .dashboard-title {
    font-family: "Poppins-SemiBold";
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-top: 0.6rem;
    // flex:1.7 ;
    flex: 1;
    text-align:center;
    color: #252546;
  }
  Link:hover {
    text-decoration: none !important;
    
  }
  .search-bar{
    background: aqua;
    display: flex;
  }
  .name{
    text-transform: capitalize;
  }
  .icon-style{
    width: 19px;
    height: 19px;
  }
  .user-name{
    font-family: "Poppins";
    font-size: 14px;
    color: #252546;
    padding: 0px 5px 0px 5px;
  }
}