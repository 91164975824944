.AdminAccessMsg-container {
  // height: 100%;
  // width: 50%;
  // display: flex;
  // flex-direction: column;
  // overflow-x: hidden;
  // overflow-y: auto;
  background-color:transparent;

  .banner-container {
    margin: clamp(1rem, 3vw, 4rem) auto 0;
    width: 90%;
  }

  .AdminAccessTitle {
    color: #252546;
    display: flex;
    justify-content: center;
    font-family: "Poppins-bold";
    font-size: 1.7rem;
    word-break: break-all;
    // font-size: 28px;
  }

  .AdminAccessDescription {
    font-family: "Poppins";
    color: #252546;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    // font-size: 18px;

    padding: 0 1rem;
    overflow: hidden;
    text-align: center;
    flex: unset;
  }
  .address {
    text-overflow: ellipsis;
    // white-space: nowrap;
    width: 100%;
    text-align: center;
    overflow: hidden;
  }

  .AdminAccessLogoutButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.description-container{
  word-wrap: break-word;
  white-space: pre-line;

  .heading-text-underline {
    font-family: "Poppins-SemiBold";
    color: #252546;
    // text-decoration: underline;
    font-size:2rem;
  }

  .description{
    font-family: "Poppins-medium";
    font-size: clamp(0.9rem, 1.8vw, 0.5rem);
    opacity: 0.9;
    color: #252546;
    margin-top: 1rem;
  }
}