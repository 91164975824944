.editable-container{

    
   .image{
        width: 60%;
        height: 80px;
        margin-top: 20px;
        border: 1px  dashed #F4B540;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
    }
    .passport-row{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .image-label{
        margin-top: 12px;
        font-size:16px ;
        color: #252546;
        font-family: "Poppins-medium";

        // background-color: #F4B540;
    }
    .price{
        margin-top: 10px;
    }
    
    ::-webkit-scrollbar {
        display: none;
    }

    
}
.editable-container::-webkit-scrollbar {
    width: 0; 
    height:0
  }