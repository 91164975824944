.main-content {
  margin-left: 4rem;
  flex: 1;

  .heading-text {
    font-family: "Poppins-Black";
    font-size: clamp(1.4rem, 3.8vw, 2.2rem);
    opacity: 0.9;
    color: #252546;
    margin-top: 70px;
  }

  .social-text-wrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 64px;
  }

  .line {
    flex: 1;
    height: 1px;
    display: flex;
    background-color: #000000;
  }

  .social-text {
    font-family: "Poppins";
    font-size: 1rem;
    opacity: 0.9;
    color: #252546;
    margin-top: 20px;
  }
  .button {
    justify-content: center;
    align-items: center;
  }
  .social-container {
    margin-left: -10px;
    margin-top: 15px;
  }
}
.socialMen {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  height: min(50vw, 50vh);
  width: 100%;
  object-fit: contain;
}

@media screen and (max-width: 600px) {
  .main-content {
    margin-left: 0;
    padding: 1rem;
  }
}
