.main-page {
  background: linear-gradient(119deg, #8D2A80 0%, #252546 100%);

  .login-page-root {
  width: 100%;
  height: 100%;
  color: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .login-page-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .loot8-logo-image{
    height: clamp(3rem, 4vw, 5rem);
    width: 100%;
    object-fit: contain;
  }

  .establishment-text {
    font-family: "Poppins-Black";
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    opacity: 0.9;
  }
  .portal-text {
    font-family: "Poppins-SemiBold";
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    opacity: 0.9;
    text-align: center;
  }
  .custom-button {
    width: 320px; 
    height: 52px; 
    display: flex;
    justify-content: space-between;
  }
  }
}