.list-component{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border:1px solid #ddd ;
    // border-bottom-left-radius:5px ;
    // border-bottom-right-radius:5px ;
    border-left-width: 0;
    padding: 10px;
    
}