.bac {
  width: 100%;
  height: 45vh;
  margin-top: 20px;
  // background-image: url("../../../../assets/svg/preview.svg");
  background-color: #0000003D;
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 15rem;
  padding: 10px;
  position: relative;

}

.corner-border {
  position: absolute;
  width: 20px;
  height: 20px;

}


.bottom-left {
  bottom: -5px;
  left: -5px;
  border-left: 3px solid #252546;
  border-bottom: 3px solid #252546;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
  border-right:3px solid #F4B540;
  border-bottom: 3px solid #F4B540;
}

.top-right {
  top: -5px;
  right: -5px;
  border-top: 3px solid #252546;
  border-right: 3px solid #252546;
}

.top-left {
  top: -5px;
  left: -5px;
  border-top: 3px solid #8D2A80;
  border-left: 3px solid #8D2A80;
}

.fade-in {
  opacity: 1;
  transition: opacity 10s ease-in-out;
}

.preview-text{
  font-family: 'Poppins-Medium';
  color: #fff;
  position: absolute;
  border-radius: 50px;
  top: -15px;
  z-index: 99;
  padding: 5px 30px;
  background-color: #252546;
}


.detail-container {
  height: 100%;
  position: relative;
}
.button-container {
  // position: absolute;
  bottom: 10px;
  width: 100%;
}
@media (max-width: 900px) {
  .button-container {
    position: relative;
    width: 100%;
    margin-block: 2rem;
  }
}
.advance-setting{
  display: flex;
  justify-content: space-between;
  font-family: "Poppins-Medium";
    font-size: 14px;
    color: #252546;
    border-bottom:1px solid #DBE2ED;
    
}

#walktrough-tooltip{
  font-family: 'Poppins-Medium';
  flex:1;

  .skip-tour{
    background: #F0ECEC;
    outline: none;
    border-radius: 8px;
    border: 0;
    padding: 10px;

  }

  #walkthrough-content{
    color: #fff;
    word-wrap: break-word;
    max-width: 28rem;
  }

}


