.billboards-container {

  overflow: overlay;
  height: 100%;
  width: 100%;
  background-color: #FDFDFD;
  background-image: url("../../assets/images/background/whiteTexture-R.png");
  background-size: 545px auto;
  background-position: top right;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;



  .right-panel-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
    overflow: auto;
    
  }

  @media screen and (max-width:961px) {
    .right-panel-container{
      width: 100%;
    }
  }

  .left-panel-container {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: overlay;

  }
  .collective-view-link {
    font-family: "Poppins";
    font-size: 1rem;
    line-height: 1.2rem;
    color: #252546;
    display: flex;
    justify-content: flex-end;
    a:hover {
      color: #252546 !important;
      cursor: pointer;
    }
  }
  .preview-container{
    width: 100%;
    min-height: 100vh; 
    background-image: url("../../assets/images/background/blueTexture.png");
    background-size:  cover; 
    background-repeat: no-repeat; 
    box-sizing: border-box;
    background-color: white;
    border-image-width: 150px;
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    padding-top: 1rem;
    position: relative;
  }

  .address{
  font-family: 'Poppins-Medium';
  font-size: 16px;
  color: black;
  }
}

.user-name {
  font-family: "Poppins";
  font-size: 14px;
  color: #252546;
  padding: 0px 5px 0px 5px;
}

.preview-modal-container{
  .form-container{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
    .form-container::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
}