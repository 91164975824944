
.onboarding-profile-page {    
    .left-container {
        .heading-text {
            font-family: "Poppins-Black";
            font-size: 20px;
            opacity: 0.9;
            color: #252546;
            margin-top: 20px;
        }
        .social-container{
            // margin-left: -10px;
            // padding-top: 20px;
            // overflow-y: scroll;
            // overflow-x: hidden;
            // height: 80vh;
            // padding-right: 10px;
        }
    }
    
    .description-container {
       margin-top: 30px;
       .description-title {
            font-size: 25px;
            font-family: "Poppins-SemiBold";
            color: #000;
            text-decoration: underline;
        }
        .description {
            font-size: 16px;
            font-family: "Poppins-Medium";
            color: #000;
        }
    }
    
}
  