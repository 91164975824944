.main-body {
  // background-color: #00000033;
  // backdrop-filter: blur( 6px );
  // -webkit-backdrop-filter: blur( 6px );
  // padding: 1rem;
  width: 100%;
  height: 100vh;
  transition: max-width 1s;
  overflow: hidden;
  
}
@media (max-width: 1200px) {
  .main-body {
    padding-bottom: 0;
  }
}