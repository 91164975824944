.collection-container {
  // max-height: 100vh;
  // overflow: hidden;
  overflow-y: auto;
  max-height: 100vh;
  padding: 1rem;
  background-image: url("../../assets/images/background/whiteTexture-R.png");
  background-size: 545px auto;
  background-position: top right;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-color: white;

  .collection-container-title {
    font-family: "Poppins-SemiBold";
    font-size: 1.1rem;
    color: #252546;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon-style {
    width: 19px;
    height: 19px;
  }
  .user-name {
    font-family: "Poppins";
    font-size: 14px;
    color: #252546;
    padding: 0px 5px 0px 5px;
  }
  .collective-view-link {
    font-family: "Poppins";
    flex:1;
    align-items: flex-end;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #252546;
    display: flex;
    justify-content: flex-end;
    a:hover {
      color: #252546 !important;
      cursor: pointer;
    }
  }
  // .divider-container{
  //   display: flex;
  //   width: 100%;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   margin-bottom: 20px;
  // }
  // .divider-label{
  //   color: #252546;
  //   font-family: "Poppins-SemiBold";
  //   font-size: 18px;
  //   margin-top: 3px;
  //   margin-right: 5px;

  // }

  .custom-scrollbar::-webkit-scrollbar {
    width: 6px; /* Adjust the width of the scrollbar track */
    height: 6px; /* Adjust the height of the scrollbar track */
    background-color: transparent; /* Adjust the background color of the scrollbar track */
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #4b4b79; /* Adjust the color of the scrollbar thumb */
    border-radius: 6px; /* Adjust the border-radius of the scrollbar thumb */
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #464690; /* Adjust the color of the scrollbar thumb on hover */
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #4b4b79; /* Adjust the color of the scrollbar thumb when active/pressed */
  }
  a:hover {
    // color: #ebc0d7 !important;
    text-decoration: underline !important;
  }
}

@media (max-width: 1200px) {
  .collection-container {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 1rem;
    max-width: 100%;
  }
}
