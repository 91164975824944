.background-container{
    width: 100%;
    height:100vh; 
    background-image: url("../../../assets/images/background/whiteTexture.png");
    background-size: 15rem auto;
    background-position: top left;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-color: white;
    border-image-width: 150px;
    display: flex;
    flex-direction: column;

    .bottomTextContainer {
      font-family: "Poppins-Medium";
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 1rem;
      color: #252546;
    }
}

@media screen and (max-width:600px) {
  .bottomTextContainer{
    margin-left: 0;
    padding: 1rem;
  }
}
 