@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("assets/fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("assets/fonts/Poppins-Medium.ttf");
}

.app {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar-thumb {
  background-color: #999999; /* Adjust the color of the scrollbar thumb */
  border-radius: 6px; /* Adjust the border-radius of the scrollbar thumb */
}
::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar track */
  height: 6px; /* Adjust the height of the scrollbar track */
  background-color: transparent; /* Adjust the background color of the scrollbar track */
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

